<template>
  <div class="layout-dashboard">
    <div class="p-grid">
      <Dialog
        :closable="false"
        :modal="true"
        header="Confirmation du réabonnement"
        :maximizable="true"
        v-model:visible="getenabledWarningSubscriptionDialog"
        :breakpoints="{ '960px': '80vw', '640px': '100vw' }"
        :style="{ width: '80vw' }"
      >
        <template #header>
          <div class="p-d-flex p-jc-between p-dialog-titlebar">
            <a href="https://www.cnscl.sn/" class="flex align-items-center justify-content-center">
              <img width="120" src="assets/layout/images/logocnscl.png" alt="CNSCL" />
            </a>
            <h4 class="p-ml-3 p-dialog-title">Réabonnement</h4>
          </div>
        </template>
        <section class="p-dialog-content">
          <div class="text-xl">
            Pour continuer à accéder à toutes les fonctionnalités de la plateforme du CNSCL, vous devez renouveler votre
            abonnement avant le <b>31 mars {{ new Date().getFullYear() }}</b>
          </div>
          <Divider />
          <p v-if="loggedUser?.entreprise.rangSouscriptionEnCours">
            Votre entreprise est {{ tarifications.rang }}
            <span class="text-lg p-font-bold" style="font-weight: bold">
              {{ tarifications?.filter((e) => e.rang === loggedUser?.entreprise.rangSouscriptionEnCours)[0]?.adherant }}
            </span>
          </p>
          <Divider />
          <Message severity="warn" class="font-italic" :closable="false"
            >Si le statut de votre entreprise a évolué, merci de prendre contact avec le secrétariat technique du CNSCL
            pour sa mise à jour
            <br />
            <Divider />
            Si le statut de votre entreprise n'a pas évolué, alors vous pouvez procéder au paiement de votre abonnement
            d’un montant de
            <span class="text-red-700 p-font-bold"
              ><b>
                {{
                  new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'XOF' }).format(
                    tarifications?.filter((e) => e.rang === loggedUser?.entreprise.rangSouscriptionEnCours)[0]
                      ?.montant_xof
                  )
                }}
              </b></span
            >
          </Message>
        </section>
        <template #footer>
          <Button label="Retour à l'accueil" @click="subscribeToThisYear()" icon="pi pi-times" class="p-button-text" />
          <Button
            label="Procéder au paiement"
            @click="gotoPaymentRoute()"
            icon="pi pi-check"
            class="p-button"
            autofocus
          />
        </template>
      </Dialog>
      <div class="p-col-12 p-md-12">
        <div v-show="isSubscribing(currentSubscription)" class="card card-w-title">
          <h5>Compléter votre inscription</h5>
          <p>
            Pour valider votre inscription, veuillez compléter votre profil et les informations de votre entreprise.
          </p>
          <!-- <Step :model="nestedRouteItems" :readonly="true" /> -->
          <router-view />
          <br />
          <Button
            v-show="isFillingUserForm(currentSubscription) || isFillingEnterpriseForm(currentSubscription)"
            @click="redirectTo"
            label="Reprendre"
            class="p-button-rounded p-button-info p-mr-2 p-mb-2"
          />
          <Button
            v-show="hasNotStarted(currentSubscription)"
            @click="redirectTo"
            label="Commencer"
            class="p-button-rounded p-button-info p-mr-2 p-mb-2"
          />
        </div>

        <div v-show="isWaitingSubscriptionValidate(currentSubscription)" class="card card-w-title">
          <h5>Merci d'avoir complété votre inscription.</h5>
          <p>L'équipe du ST CNSCL examine les renseignements sur votre entreprise.</p>
          <br />
        </div>

        <div v-show="isSubscriptionRejected(currentSubscription)" class="card card-w-title">
          <h5>Votre inscription a été rejetée pour les raisons suivantes :</h5>
          <h5 style="color: #c60030">{{ currentSubscription?.motifRejet }}</h5>
          <br />
          <Button @click="redirectTo" label="Reprendre" class="p-button-rounded p-button-info p-mr-2 p-mb-2" />
        </div>

        <div v-show="isSubscriptionValidated(currentSubscription)" class="card card-w-title">
          <h5>Votre inscription a été approuvée :</h5>
          <p>
            L'équipe du ST CNSCL a validé les renseignements sur votre entreprise. Vous pouvez maintenant passer au
            paiement
          </p>
          <br />
          <Button
            @click="redirectTo"
            label="Payez votre abonnement"
            class="p-button-rounded p-button-info p-mr-2 p-mb-2"
          />
        </div>

        <div class="card card-w-title" v-show="isPaymentSubmitted(currentSubscription)">
          <h5>Merci d'avoir soumis les informations sur votre paiement.</h5>
          <p>L'équipe du ST CNSCL examine les détails relatifs à votre paiement.</p>
        </div>
        <div class="card card-w-title" v-show="isPreviousSubscAndLastToTrial()">
          <h5>Merci d'avoir soumis les informations sur le paiement de votre réabonnement .</h5>
          <p>L'équipe du ST CNSCL examine les détails relatifs à votre réabonnement .</p>
        </div>

        <div class="card card-w-title" v-show="isPaymentRejected(currentSubscription)">
          <h5>Votre paiement a été rejeté pour les raisons suivantes :</h5>
          <p class="text-red-custom">{{ currentSubscription?.motifRejet }}</p>
          <br />
          <Button @click="redirectTo" label="Reprendre" class="p-button-rounded p-button-info p-mr-2 p-mb-2" />
        </div>
        <!-- {{ isPreviousSubscAndLastToTrial() }} -->
        <!-- PAYMENT IN TRIAL OR SUSPENDED MODE WHEN NEW RESUBSC  -->
        <div v-if="!isPreviousSubscAndLastToTrial()">
          <Message severity="error" :closable="false" v-show="isSuscriptionSuspended(currentSubscription)">
            <span :style="{ fontSize: '1em' }">Votre accès est suspendu ! Veuillez vous réabonner.</span>
          </Message>
          <Message severity="error" :closable="false" v-show="isSuscriptionTrial(currentSubscription)">
            <span :style="{ fontSize: '1em' }"
              >Merci de vous réabonner avant le <b>31 mars {{ new Date().getFullYear() }}</b
              >.</span
            >
          </Message>
        </div>

        <div class="card card-w-title" v-show="isPaymentValidated(currentSubscription)">
          <h5>Merci d'avoir complété votre inscription.</h5>
          <p>Votre paiement a été validé avec succès !</p>
          <br />
          <Button :loading="isloadReceiptDownload" @click="handleOnDownloadReceipt">Télécharger le reçu</Button>
        </div>

        <!--<div v-if="ppm.motifRejet" :style="{ padding: '20px' }">
          <div class="card card-w-title p-col-12 p-xl-12">
            <h5>Votre plan de passation de marché a été rejeté pour les raisons suivantes :</h5>
            <h5 style="color: #c60030">{{ ppm.motifRejet }}</h5>
          </div>
        </div>-->
      </div>

      <div class="p-col-12 p-md-6 p-xl-6">
        <div class="card no-gutter widget-overview-box widget-overview-box-1">
          <span class="overview-icon">
            <i class="pi pi-user"></i>
          </span>
          <span class="overview-title">Mon Profil</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number"></div>
              <div class="overview-number">{{ loggedUser?.firstName }} {{ loggedUser?.lastName }}</div>
              <div class="overview-subtext"></div>
              <!--<br />
                <div
                  v-show="role == 'ROLE_TEMP_REJETE' && loggedUser?.entreprise?.isTmpValid === true"
                  class="overview-subtext"
                >
                  <Button @click="userForm" label="Modifier" class="p-button-rounded p-button-info p-mr-2 p-mb-2" />
                </div>-->
            </div>
            <div class="p-col-6">
              <div class="overview-subtext">E-mail : {{ loggedUser?.email }}</div>
              <div v-show="loggedUser?.mobile !== null" class="overview-subtext">
                Contact : {{ loggedUser?.mobile }}
              </div>
              <div v-show="loggedUser?.positionDescription !== null" class="overview-subtext">
                Poste : {{ loggedUser?.positionDescription }}
              </div>
            </div>
          </div>
        </div>
        <div class="card no-gutter widget-overview-box widget-overview-box-3">
          <span class="overview-icon">
            <i class="pi pi-briefcase"></i>
          </span>
          <span class="overview-title">Mon Entreprise</span>
          <div class="p-grid overview-detail">
            <div class="p-col-6">
              <div class="overview-number"></div>
              <div class="overview-number">{{ loggedUser?.entreprise?.name }}</div>
              <div class="overview-subtext"></div>
              <!--<br />
                <div
                  v-show="role == 'ROLE_TEMP_REJETE' && user.entreprise?.isTmpValid === true"
                  class="overview-subtext"
                >
                  <Button @click="seatForm" label="Modifier" class="p-button-rounded p-button-info p-mr-2 p-mb-2" />
                </div>-->
            </div>
            <div v-show="loggedUser?.entreprise?.address !== null" class="p-col-6">
              <div class="overview-subtext">
                Numéro d'identification : {{ loggedUser?.entreprise?.identificationNumber }}
              </div>
              <div class="overview-subtext">Adresse : {{ loggedUser?.entreprise?.address }}</div>
              <div class="overview-subtext">
                Date de création : {{ getFormattedDate(loggedUser?.entreprise?.companyCreatedAt) }}
              </div>
            </div>
            <div v-show="loggedUser?.entreprise?.address == null" class="p-col-6">
              <div class="overview-subtext">
                Merci de remplir les informations de votre entreprise afin de pouvoir compléter votre inscription.
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="p-col-12 p-lg-6">
        <div class="widget-pricing-card">
          <h4>COMMENT ÇA MARCHE ?</h4>
          <br />
          <div style="text-align: justify">
            <p>Étape 1 : Renseigner vos informations personnelles.</p>
            <p>Étape 2 : Renseigner les informations de votre entreprise.</p>
            <p>
              Étape 3 : Payer l’abonnement annuel. Les moyens de paiement acceptés sont les cartes bancaires Mastercard
              et Visa, les virements bancaires et les chèques.
            </p>
            <p>
              Après validation de ces étapes, le secrétariat technique du CNSCL, validera votre paiement et votre
              inscription.
            </p>
            <p>
              Vous serez alors répertorié dans le réseau sénégalais des entreprises qui exercent dans les activités
              pétrolières et gazières.
            </p>
            <br />
            <p style="font-size: 1.2em">
              <b style="color: #8b0000"
                >Cliquez
                <a
                  :href="'/assets/files/Guides utilisation CNSCL/Support de formation CNSCL - Module Inscription.pdf'"
                  download
                  style="color: #ffffff"
                  >ici</a
                >
                pour télécharger le guide d'inscription.</b
              >
            </p>
          </div>
          <br />
          <br />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapActions } from 'vuex';
import http from '../services/http.service';
import LoggedUserMixin from '@/mixins/logged-user';
// import trackStates from '@/components/track-states';

export default {
  mixins: [LoggedUserMixin],
  data() {
    return {
      tarifications: [],
      isloadReceiptDownload: false,
      enableWarningSubscriptionDialog: true,
      today: new Date(),
      loggedUser: null,
      currentSubscription: null,
    };
  },
  // components:{
  //   trackStates
  // },
  computed: {
    ...mapGetters({
      getenabledWarningSubscriptionDialog: 'dialog/getenabledWarningSubscriptionDialog',
    }),
  },
  async mounted() {
    this.token = localStorage.getItem('token');
    if (this.token) {
      const config = {
        headers: { Authorization: `Bearer ${this.token}` },
      };

      //INFO USER
      try {
        const res = await http.get('/users/getProfile', config);
        this.loggedUser = res.data;

        // TODO: Remove all console.log
        if (this.loggedUser.roles.length > 0) {
          // console.log('this.loggedUser.roles', this.loggedUser.roles.length);
          if (this.loggedUser.roles[0].name === 'ROLE_ADMIN') {
            this.$router.push('/admin');
          }
          if (this.loggedUser.roles[0].name === 'VAL_INSCRIPTION') {
            this.$router.push('/admin/inscriptions');
          }
          if (this.loggedUser.roles[0].name === 'VAL_PUBLICATION') {
            this.$router.push('/admin/publications');
          }
          if (this.loggedUser.roles[0].name === 'VAL_PAIEMENT') {
            this.$router.push('/admin/payment');
          }
        }
        this.currentSubscription = this.getCurrentSubscription(this.loggedUser);
        this.getTarifications();

        // TODO: redirect to enterprise form if INITIATED (Etape 1)
        // TODO: redirect to enterprise form if REJETE (Etape 2)
        // TODO: redirect to payment if APPROUVE, PAIEMENT_REJETE (ETAPE 3)
        // TODO: redirect to payment if POSTE (FINISH)
      } catch (e) {
        // console.log('ERROR DASHBOARD => ' + e);
        // this.$router.push('/login');
      }
    } else {
      // console.log('3. No Token');
      // this.$router.push('/login');
    }
  },
  methods: {
    ...mapActions({
      subscribeToThisYear: 'dialog/subscribeToThisYear',
    }),
    async getTarifications() {
      await http.get('/tarifs?limit=100?sort=montant_xof,asc', this.config).then((res) => {
        this.tarifications = res.data;
        this.tarifications.sort((a, b) => b.montant_xof - a.montant_xof);
      });
    },
    isPreviousSubscAndLastToTrial() {
      // console.log(this.getLoggedUser());
      const length = this.getLoggedUser().entreprise?.abonnements.length;
      if (length > 1) {
        const lastSubscription = this.getLoggedUser().entreprise?.abonnements[0];
        if (
          this.isTrialPaymentPending(lastSubscription) &&
          (this.isSuscriptionSuspended(this.currentSubscription) || this.isSuscriptionTrial(this.currentSubscription))
        ) {
          return true;
        }
      } else {
        return;
      }
    },
    //TODO: To move in a mixin.
    gotoPaymentRoute() {
      this.subscribeToThisYear();
      this.$router.push('/menu/payment');
    },

    getFormattedDate(date) {
      return moment(date).format('DD/MM/YYYY');
    },

    autoRedirectTo() {
      if (this.isFillingUserForm(this.currentSubscription)) this.$router.push('/menu/seat');
      if (this.isFillingEnterpriseForm(this.currentSubscription)) this.$router.push('/menu/seat');
      if (this.isPaymentSubmitted(this.currentSubscription)) this.$router.push('/menu/payment');
      if (this.isWaitingSubscriptionValidate(this.currentSubscription)) this.$router.push('/menu/confirmation');
    },

    redirectTo() {
      if (this.hasNotStarted(this.currentSubscription)) this.$router.push('/menu');
      if (this.isSubscriptionRejected(this.currentSubscription)) this.$router.push('/menu');
      if (this.isFillingUserForm(this.currentSubscription) || this.isFillingEnterpriseForm(this.currentSubscription))
        this.$router.push('/menu/seat');
      if (this.isPaymentRejected(this.currentSubscription) || this.isSubscriptionValidated(this.currentSubscription))
        this.$router.push('/menu/payment');
    },

    async handleOnDownloadReceipt() {
      this.isloadReceiptDownload = true;

      await http
        .get(`/abonnements/${this.currentSubscription.id}/receipt.pdf`, {
          responseType: 'blob',
          headers: { Authorization: `Bearer ${this.token}` },
        })
        .then((res) => {
          this.isloadReceiptDownload = false;

          var FILE = window.URL.createObjectURL(new Blob([res.data]));

          var docUrl = document.createElement('a');
          docUrl.href = FILE;
          docUrl.setAttribute('download', `recu-de-paiement-${this.abonnementId}.pdf`);
          document.body.appendChild(docUrl);
          docUrl.click();
        });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep(.stepsdemo-content) {
  padding: 1em 0;
  p {
    font-weight: 400;
    display: inline-block;
    vertical-align: middle;
    font-size: 18px;
    margin: 0;
  }
  i {
    vertical-align: middle;
    font-size: 1.5em;
    margin: 0;
  }
}
.contextmenu-image {
  width: 100%;
}
.layout-dashboard {
  .orders {
    h4 {
      margin-bottom: 20px;
    }

    .p-button {
      margin-top: -20px;
    }

    .order-tabs {
      margin-bottom: 1rem;

      .order-tab {
        padding: 1rem 2rem 2rem 1rem;
        position: relative;
        transition: box-shadow 0.2s;

        &:hover {
          box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.15);
          cursor: pointer;
        }

        i {
          font-size: 1rem;
          vertical-align: middle;
        }

        .order-label {
          margin-left: 0.25rem;
          vertical-align: middle;
        }

        .stat-detail-icon {
          position: absolute;
          right: 1rem;
          top: 2.25rem;
          height: 1rem;
          width: 1rem;
        }

        img {
          position: absolute;
          bottom: 0;
          left: 5%;
          width: 90%;
        }
      }
    }
  }

  .overview-chart {
    overflow: auto;
  }

  .dashbard-demo-dropdown {
    min-width: 8rem;
    margin-left: auto;
  }

  .product-badge {
    border-radius: 2px;
    padding: 0.25em 0.5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 0.3px;

    &.status-instock {
      background: #c8e6c9;
      color: #256029;
    }

    &.status-outofstock {
      background: #ffcdd2;
      color: #c63737;
    }

    &.status-lowstock {
      background: #feedaf;
      color: #8a5340;
    }
  }
}
.text-red-custom {
  color: #c63737;
}
</style>
